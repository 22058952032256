export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-2",
    BUCKET: "zftutorial"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://xa7mnh6fn6.execute-api.us-east-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_v6JVG0LVn",
    APP_CLIENT_ID: "7vdhvpm43hnjk4qkbptq1dsd50",
    IDENTITY_POOL_ID: "us-east-2:84bbac51-b1ac-4a7e-8a77-75de74d17e2e"
  }
};